import React from "react";
import { useRouter } from "next/router";
import {
  setOptions,
  getSession,
  Provider as AuthProvider,
} from "next-auth/client";

//import App from "next/app";
import Head from "next/head";
import Router from "next/router";
import { CookiesProvider } from "react-cookie";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/nextjs-argon-dashboard-pro.scss?v1.1.0";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import "../node_modules/react-simple-tree-menu/dist/main.css";
import { AppWrapper } from "../context/state";
import NProgress from "nprogress"; //nprogress module
import "nprogress/nprogress.css"; //styles of nprogress
import axios from "axios";
import "components/StackedProgressBars.css";
import "components/Legend.css";
import "assets/css/secondNav.css";
import "./ResponsiveTable.css";
import "leaflet/dist/leaflet.css";
import "../assets/css/tickets.css";

axios.defaults.timeout = 300000;
//Binding events.
Router.events.on("routeChangeStart", () => {
  NProgress.set(0.4);
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());
const customerName = process.env.CUSTOMER;
const localTime = process.env.TIME_ZONE_CS;
//console.log("AAAAAAAAAAAA>>>>>>>>",localTime)
import moment from "moment-timezone";

// moment.tz.setDefault("Asia/Kolkata");
//
// if (localTime === "true") {
//   const guessedTimeZone = moment.tz.guess();
//   // console.log("guessedTimeZOne",guessedTimeZone)
//   moment.tz.setDefault(guessedTimeZone);
// } else {
//   moment.tz.setDefault("Asia/Kolkata");
// }

import "assets/vendor/fontawesome-free/css/all.min.css";
import "components/multi-select.css";

function App(props) {
  const router = useRouter();
  const { Component, pageProps, session } = props;
  const Layout = Component.layout || (({ children }) => <>{children}</>);
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>Solar Dash</title>
      </Head>
      <AuthProvider
        options={{ site: process.env.NEXTAUTH_URL }}
        session={session}
      >
        <React.Fragment>
          <AppWrapper>
            <Layout>
              {["/meter-data", "/meter-data-new", "/audit"].includes(
                router.pathname,
              ) ? (
                <Component key={router.asPath} {...pageProps} />
              ) : (
                <Component {...pageProps} />
              )}
            </Layout>
          </AppWrapper>
        </React.Fragment>
      </AuthProvider>
      <style jsx global>
        {`
          .body {
            background-color: 'white';
            box-shadow: none;
          }
          .navbar-inner {
            background: linear-gradient(87deg,#172b4d 0, #142645 100%) !important;
          }
          .SideBarListItems {
            background-color: #172b4d;
            border-color: #8898aa;
          }
          .form-control {
            margin-bottom: 1rem;
          }
          .SideBarListItems.active  {
            background-color: #007bff;
            border-color: #8898aa;
            font-size: 0.875rem;
          }
          .rstm-tree-item {
            color: #f6f9fc;
            font-size: 0.875rem;
            padding: 0.45rem 1rem;
            padding-left: 0.2rem;
            border: none;
          }
          .rstm-tree-item-group {
            padding: 0.2rem;
          }
          .rstm-tree-item--active { 
            background: #007bff,
            border: none;
          }
          .rstm-search {
            height: 2.4rem;
            border-radius: 2rem;
            display: table;
            margin: 0 auto;
            width: 90%;
            margin-bottom: 0.5rem;
          }
          .dropdown {
            width: 100%
          }
          .some-custom-class {
            background: white
          }
          .slide-pane__subtitle {
            color: white;
            margin: auto;
          }
          .slide-pane__close svg {
            color: #32325D;
          }
          .slide-pane .slide-pane__title {
            margin: auto;
            color: #32325D;
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.375rem;
          }
          @media (max-width: 800px) {
          .sidenav-toggler-dark .sidenav-toggler-line {
            background-color: black;
        }
      }
      .sidenav-toggler-line {
        background-color: white;
      }
          .sidenav-header {
            height: 3rem;
          }
          .scrollbar-inner {
            background: linear-gradient(87deg,#172b4d 0, #142645 100%) !important;
          }
          .navbar-vertical {
            background: linear-gradient(87deg,#172b4d 0, #142645 100%) !important;
            border-style: solid;
            margin-bottom: 0.5rem;
          }
          .slide-pane__overlay {
            z-index: 120;
          }
        //.DatePicker {
        //  margin: 0rem;
        //}
        .navbar-top {
          display: block;
        }
        #nprogress {
          pointer-events: none;
        }
        #nprogress .bar {
          background: red;
          position: fixed;
          z-index: 1031;
          top: 0;
          left: 0;
          width: 100%;
          height: 0.3125rem;
        }
        #nprogress .peg {
          display: block;
          position: absolute;
          right: 0;
          width: 100px;
          height: 100%;
          box-shadow: 0 0 10px red, 0 0 5px red;
          opacity: 1;
          -webkit-transform: rotate(3deg) translate(0px, -4px);
          -ms-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
        }
        #nprogress .spinner {
          display: "block";
          position: fixed;
          z-index: 1031;
          top: 15px;
          right: 15px;
        }
        #nprogress .spinner-icon {
          width: 18px;
          height: 18px;
          box-sizing: border-box;
          border: solid 2px transparent;
          border-top-color: red;
          border-left-color: red;
          border-radius: 50%;
          -webkit-animation: nprogresss-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
        }
        .nprogress-custom-parent {
          overflow: hidden;
          position: relative;
        }
        .nprogress-custom-parent #nprogress .spinner,
        .nprogress-custom-parent #nprogress .bar {
          position: absolute;
        }
        @-webkit-keyframes nprogress-spinner {
          0% {
            -webkit-transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
          }
        }
        @keyframes nprogress-spinner {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
     
        .planavailability tbody tr td{vertical-align: middle;}
        .planavailability .reset-expansion-style{border-top: 0;background: #e6eef3;}
        .planavailability tbody tr td input{height:2.35rem;margin:0;width:100%}
        .planavailability .input-group-text{padding:0.3rem}
        .planavailability thead tr th{vertical-align: middle!important}
        .manualticketmodel .modal-content{max-width:750px;margin:0 auto}
        .popupplantmeterdata .modal-content{max-width:950px;margin:0 auto}
        .manualticketmodel .DatePicker{position:relative;right:auto;top:auto;width:100%}
        .manualticketmodel .DatePicker input.DatePicker__input{width:100%;height: 41px;border-radius: 5px;border: 1px solid #e9ecef;}
        .preventiveScheduleModel .DatePicker{position:relative;right:auto;top:auto;width:100%}
        .preventiveScheduleModel .DatePicker input.DatePicker__input{width:100%;height: 41px;border-radius: 5px;border: 1px solid #e9ecef;}
        .manualticketmodel .modal-header,.popupplantmeterdata .modal-header,.abtticketmodel .modal-header{padding-bottom: 0;}
        .manualticketmodel .modal-body,.popupplantmeterdata .modal-body,.abtticketmodel .modal-body{padding-top: 0;}
        .manualticketmodel .modal-footer,.abtticketmodel .modal-footer{display:none}
        .popupplantmeterdata .close{padding:0}
        .no-list-bullet{list-style:none;font-weight: bold;margin-bottom: 8px;padding-bottom: 8px;position:relative}
        .plantnametext{font-weight: bold;margin-bottom: 7px;margin-top: 4px;}
        .popupplantmeterdata ul{list-style:square;    padding: 2px 15px;}
        .abtticketmodel .modal-content{max-width:950px;margin:0 auto}
        .abtticketmodel .card{border: 0;box-shadow: none;}
        .cardtablebody{ padding: 0 12px;}
        .no-list-bullet:before {position: absolute;left: 0;bottom: 0;width: 120px;height: 2px;content: "";background-color: #1166ce;}
        .popupplantmeterdata ul.pl-4:nth-child(odd) {background: #f5f5f5;}
        .plantlistcard{border-bottom: 1px solid #ddd;padding: 6px 0px 0px 0px;clear: both;}
        .plantlistcardul{padding:0;list-style:none;margin-top:5px}
        .plantlistcarddetail .keylabel,.plantlistcarddetail .keyval{display:block;float: left;line-height: 20px;}
        .plantlistcarddetail .keylabel{color: #8898aa;font-size: 13px;font-weight: 500;width:60%}
        .plantlistcarddetail .keyval{color: #000000;font-size: 14px;text-align: right;width:40%}
        .greenval{color:green !important;}  .redval{color:red!important;}
        .iconbase{font-size: 0.7rem;position: absolute;right:5px;top:10px;z-index: 1;cursor: pointer;}
        .plantlistcarddetail {display: inline-block;width: 100%;}
        .orangeval{color:orange !important}
    //    .ic_ {height: 1.5rem;width: 1.5rem;fill: currentColor;}.navoptions li a {
    //padding: 1.0625rem 1.25rem;color: #767676;font-size: 1rem;font-weight: 600;line-height: 1.375rem;border: 0;margin-bottom: 0;border-bottom: 0.1875rem solid transparent;display: block;}
.navoptions li a:hover, .navoptions li a.active {margin-bottom: 0;color: #5E72E4;border-bottom: 0.1875rem solid #5E72E4;}
.top-nav-buttons {position: absolute;right: 1.875rem;top: 0.8125rem;}.top-nav-buttons .btn {-webkit-box-shadow: none;box-shadow: none;background: #fff;border: 0;}
.top-nav-buttons .btn:hover {color: #525f7f;}.top-nav-buttons .btn:hover, .top-nav-buttons .btn:focus, .top-nav-buttons .btn:active {background: #fff !important;-webkit-transform: none;transform: none;
  -webkit-box-shadow: none !important;box-shadow: none !important;}@media (max-width: 767.98px) {.top-nav-buttons {right: 0.9375rem;}}
.top-nav {padding: 0 1.875rem;height: 3.75rem;-webkit-box-shadow: inset 0 -0.0625rem 0 0 #DDDDDD;box-shadow: inset 0 -0.0625rem 0 0 #DDDDDD;}
@media (max-width: 767.98px) {.top-nav {padding: 0 0.9375rem;}.carddivportfolio{max-height: fit-content !important;}.d-xs-block{display:block!important}.backtohome{font-size: 0.9rem;right:1rem !important}
.hiddenheader{display:none !important}.shownheader{font-size: 0!important;height: 0;padding: 0 !important;line-height: 0;width: 0;min-height: 0 !important;border: 0 !important;}
#select-filter-column-Region{    margin-top: 1.6rem;position:absolute}#select-filter-column-Client{    margin-top: 18px;}.order-4{display:none}
#datatable-basic_filter{font-size:0}#datatable-basic_filter select{    width: 18rem;margin-left: -2rem;height: 45px;}.d-xs-none{display:none}}
.customfilter{width: 18rem;}}.react-bootstrap-table .sortable .order-4::after,.react-bootstrap-table .sortable .caret-4-desc::after,.react-bootstrap-table .sortable .caret-4-asc::after{left: 0;top: 9px;bottom:}
   @media (min-width: 767.98px) { .customfilter{ position: absolute;top: 12px;right: 0; width: 0; height: 0; appearance: inherit; border: 0; background-size: 75%;
    background-repeat: no-repeat; background-position: 1px -2px; background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAxklEQVRIie2VsQ2CQBhGn2hldARHsDBMYWHCHlowhxYMQsEWFoYZTGQDKNEECz8SIIp3gqHhdfff+747igsw8oVJY1303ev0VGhFxOtLQotMqExkIq+ATIGdgb+VmylrhK/QDVi0eHPgKtc3LQeYArGCxxbvJCdWxgoXeAB3YPNmfw3kclzb8pJAN7w0bugAZ+0Fv5YDLIFERfvK/KBZIqcTnsrSyizVzOtaXlJQf+XN9Uf+/pLHA4Y/YGbpW/8vBvkfjNR4AiojK2xKXsIaAAAAAElFTkSuQmCC')}}
.togglecolumnlist {width: 14rem;font-size: 1rem;position: absolute;z-index: 9999;background: #fff;padding: 10px;box-shadow: 0px 0px 5px #0000003b;top:50px;right:24px}
.togglecolumnlist .btn-group{display: block;}.togglecolumnlist .btn{background: transparent !important;color: #403f3f !important;width: 100%;text-align: left;padding:0;padding-left:20px;box-shadow:none!important}
.togglecolumnlist .btn:before{content:"";position:absolute;left:0;border:2px solid #403f3f;width:15px;height:15px;margin-top:2px}
.togglecolumnlist .btn.active:before{content:"";position:absolute;left:0;border:2px solid #403f3f;width:15px;height:15px;margin-top:2px}
.togglecolumnlist .btn.active:after {content:"";left: 5px;top: 4px;width: 5px;height: 9px;border: solid #000;border-width: 0 2px 2px 0;transform: rotate(45deg);position:absolute}
.dfdfdfdf{display:none}
.columntogglebtn{position:absolute;right:25px;top:24px;cursor:pointer}

`}
      </style>
    </>
  );
}

App.getInitialProps = async (context) => {
  const session = await getSession(context);

  return {
    session,
  };
};

export default App;
